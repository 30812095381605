import { config } from '@/config';

const policyTypes = (user, returnAllValues) => {
  if (config().BROKERAGEASB) {
    return [
      { value: 'advantage', label: 'Medicare Advantage' },
      { value: 'supplement', label: 'Medicare Supplement' },
      { value: 'medicare_part_d', label: 'Medicare Part D' },
      { value: 'health_other', label: 'Health (other)' },
      { value: 'other', label: 'Other' }
    ];
  }

  let valueC1 = [
    { value: 'acc_protection', label: 'Accident Protection' },
    { value: 'acc_illness', label: 'Accident-Illness' },
    { value: 'cancer', label: 'Cancer' },
    { value: 'recovery_care', label: 'Recovery Care' },
    { value: 'dental', label: 'Dental' },
    { value: 'dental_vision', label: 'Dental-Vision' },
    { value: 'life', label: 'Life' },
    { value: 'advantage', label: 'Medicare Advantage' },
    { value: 'hosp_indemnity', label: 'Hospital Indemnity' },
    { value: 'supplement', label: 'Medicare Supplement' },
    { value: 'other', label: 'Other' },
    { value: 'part_d', label: 'Part D' },
    { value: 'pdp', label: 'PDP' },
    { value: 'short_term_m_med', label: 'Short Term Maj Med' }
  ];
  let value = [
    { value: 'supplement', label: 'Medicare Supplement' },
    { value: 'advantage', label: 'Medicare Advantage' },
    { value: 'annuity', label: 'Annuity' },
    { value: 'life', label: 'Life' },
    { value: 'health', label: 'Health' },
    { value: 'ltc', label: 'LTC' },
    { value: 'other', label: 'Other' },
    { value: 'final_expense', label: 'Final Expense' },
    { value: 'health_other', label: 'Health (Other)' },
    { value: 'life_other', label: 'Life (Other)' },
    { value: 'major_medical', label: 'Major Medical' },
    { value: 'medicare_part_d', label: 'Medicare Part D' },
    { value: 'single_premium', label: 'Single Premium' }
  ];

  if (config().POLICY_TYPE_PRENEED) {
    value = [...value, { value: 'pre_need', label: 'PreNeed' }];
  }
  if (user?.aris) {
    value = [
      ...value,
      { value: 'dental', label: 'Dental' },
      { value: 'cancer', label: 'Cancer' },
      { value: 'accident', label: 'Accident' },
      { value: 'critical_illness', label: 'Critical Illness' },
      { value: 'short_term_major_medical', label: 'Short Term Major Medical' },
      { value: 'heart_stroke', label: 'Heart or Stroke' },
      { value: 'vision', label: 'Vision' },
      { value: 'recovery_care', label: 'Recovery Care' }
    ];
  }
  if (returnAllValues && config().AEGLEADS_SYSTEM) {
    return [...valueC1, ...value];
  }
  if (user?.c_1) {
    return valueC1;
  }
  if (config().CAREINADVANCE) {
    return [
      { value: 'voyage', label: 'Voyage' },
      { value: 'course', label: 'Course' },
      { value: 'destination', label: 'Destination' },
      { value: 'transitions', label: 'Transitions' },
      { value: 'other', label: 'Other' }
    ];
  }
  return value;
};

const policySources = (user, props) => {
  if (user?.c_1 || user?.aris) {
    // TODO I'm open to a better way of doing  this but
    // TODO When displaying the the policy source it needs to
    // TODO be that the label and value are the same unlinke contact source.
    // TODO however it should only display the sources related to the corp just
    // TODO like contact source.

    // ** For now this should be fine.

    return props.sources.map(entry => {
      return { value: entry.label, label: entry.label };
    });
  }

  const value = [
    { value: 'existing', label: 'Existing' },
    { value: 'import', label: 'Import' },
    { value: 'auto_submitted', label: 'Auto Submitted' },
    { value: 'auto_issued', label: 'Auto Issued' }
  ];

  if (config().ASB_SYSTEM) {
    return [...value, { value: 'ASB', label: 'ASB' }];
  } else {
    return value;
  }
};

const policyStatuses = user => {
  if (user?.aris) {
    return [
      { value: '', label: '' },
      { value: 'active', label: 'Active' },
      { value: 'pending', label: 'Pending' },
      { value: 'pending_requirements', label: 'Pending Requirements' },
      { value: 'submitted', label: 'Submitted' },
      { value: 'suspended', label: 'Suspended' },
      { value: 'taken_dealth', label: 'Taken - Dealth' },
      { value: 'taken_cancelled', label: 'Taken - Cancelled' },
      { value: 'taken_lapsed', label: 'Taken - Lapsed' },
      { value: 'taken_terminated', label: 'Taken - Terminated' },
      { value: 'not_taken', label: 'Not Taken' },
      { value: 'not_taken_declined', label: 'Not Taken - Declined' },
      { value: 'not_taken_disenrolled', label: 'Not Taken - Disenrolled' },
      { value: 'not_taken_rejected', label: 'Not Taken - Rejected' },
      { value: 'not_taken_withdrawn', label: 'Not Taken - Withdrawn' },
      { value: 'not_taken_cancelled', label: 'Not Taken - Cancelled' },
      { value: 'not_taken_incomplete', label: 'Not Taken - Incomplete' },
      { value: 'unknown', label: 'Unknown' },
      { value: 'blank', label: 'Blank' },
      { value: 'cancelled', label: 'Cancelled' },
      { value: 'death', label: 'Death' },
      { value: 'declined', label: 'Declined' },
      { value: 'disenrolled', label: 'Disenrolled' },
      { value: 'inactive', label: 'Inactive' },
      { value: 'lapse_pending', label: 'Lapse Pending' },
      { value: 'lapsed', label: 'Lapsed' },
      { value: 'rejected', label: 'Rejected' },
      { value: 'terminated', label: 'Terminated' },
      { value: 'unmapped', label: 'Unmapped' },
      { value: 'withdrawn', label: 'Withdrawn' }
    ];
  }

  if (user?.c_1) {
    return [
      { value: '', label: '' },
      { value: 'active', label: 'Active' },
      { value: 'pending', label: 'Pending' },
      { value: 'submitted', label: 'Submitted' },
      { value: 'suspended', label: 'Suspended' },
      { value: 'taken_dealth', label: 'Taken - Dealth' },
      { value: 'taken_cancelled', label: 'Taken - Cancelled' },
      { value: 'taken_lapsed', label: 'Taken - Lapsed' },
      { value: 'taken_terminated', label: 'Taken - Terminated' },
      { value: 'not_taken', label: 'Not Taken' },
      { value: 'not_taken_declined', label: 'Not Taken - Declined' },
      { value: 'not_taken_disenrolled', label: 'Not Taken - Disenrolled' },
      { value: 'not_taken_rejected', label: 'Not Taken - Rejected' },
      { value: 'not_taken_withdrawn', label: 'Not Taken - Withdrawn' },
      { value: 'not_taken_cancelled', label: 'Not Taken - Cancelled' },
      { value: 'not_taken_incomplete', label: 'Not Taken - Incomplete' },
      { value: 'unknown', label: 'Unknown' },
      { value: 'approved', label: 'Approved' },
      { value: 'declined/closed', label: 'Declined/Closed' },
      { value: 'expired', label: 'Expired' },
      { value: 'not found', label: 'Not Found' },
      { value: 'blank', label: 'Blank' },
      { value: 'cancelled', label: 'Cancelled' },
      { value: 'death', label: 'Death' },
      { value: 'declined', label: 'Declined' },
      { value: 'disenrolled', label: 'Disenrolled' },
      { value: 'inactive', label: 'Inactive' },
      { value: 'lapse_pending', label: 'Lapse Pending' },
      { value: 'lapsed', label: 'Lapsed' },
      { value: 'rejected', label: 'Rejected' },
      { value: 'terminated', label: 'Terminated' },
      { value: 'unmapped', label: 'Unmapped' },
      { value: 'withdrawn', label: 'Withdrawn' }
    ];
  }

  if (config().CAREINADVANCE) {
    return [
      { value: 'pending_issue', label: 'Pending Issue' },
      { value: 'active', label: 'Active' },
      { value: 'suspended', label: 'Suspended' },
      { value: 'terminated', label: 'Terminated' }
    ];
  }

  return [
    { value: 'submitted', label: 'Submitted' },
    { value: 'issued', label: 'Issued' },
    { value: 'lapsed', label: 'Lapsed' },
    { value: 'client', label: 'Client' },
    { value: 'cancellation', label: 'Cancellation' },
    { value: 'withdrawn', label: 'Withdrawn' },
    { value: 'declined', label: 'Declined' },
    { value: 'pending_requirements', label: 'Pending Requirements' }
  ];
};

export const POLICY_TYPES = (user = null, returnAllValues = null) => policyTypes(user, returnAllValues);
export const POLICY_STATUSES = (user = null) => policyStatuses(user);

export const POLICY_SOURCES = (user = null, props) => policySources(user, props);
export const REPLACEMENT = [
  { value: 'Y', label: 'Y' },
  { value: 'N', label: 'N' }
];

export const previewLabel = (place, label, user, props) => {
  switch (place) {
    case 'policy_type':
      return POLICY_TYPES(user, true).find(el => el.value === label)?.label;
    case 'status':
      return POLICY_STATUSES(user).find(el => el.value === label)?.label;
    case 'source':
      return POLICY_SOURCES(user, props).find(el => el.value === label)?.label;
    case 'replacement':
      return REPLACEMENT.find(el => el.value === label)?.label;
    default:
      return '';
  }
};
